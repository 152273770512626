import Img from "gatsby-image";
import SEO from "../../../src/components/seo";
import Image from "../../../src/components/image";
import ImageLandscape from "../../../src/components/sub/landscape";
import PortraitsTwo from "../../../src/components/sub/portraitx2";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  Img,
  SEO,
  Image,
  ImageLandscape,
  PortraitsTwo,
  React,
  MDXTag
};