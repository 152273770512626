import Img from "gatsby-image";
import SEO from "../../../src/components/seo";
import ImageLandscape from "../../../src/components/sub/landscape.js";
import PortraitsTwo from "../../../src/components/sub/portraitx2.js";
import Image from "../../../src/components/image";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  Img,
  SEO,
  ImageLandscape,
  PortraitsTwo,
  Image,
  React,
  MDXTag
};