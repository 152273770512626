// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-layout-js": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/components/blog-post-layout.js" /* webpackChunkName: "component---src-components-blog-post-layout-js" */),
  "component---src-components-browse-blog-posts-js": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/components/browse-blog-posts.js" /* webpackChunkName: "component---src-components-browse-blog-posts-js" */),
  "component---src-pages-2017-03-16-the-comfort-economy-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-03-16-The-Comfort-Economy/index.mdx" /* webpackChunkName: "component---src-pages-2017-03-16-the-comfort-economy-index-mdx" */),
  "component---src-pages-2017-05-03-listen-up-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-05-03-Listen-Up/index.mdx" /* webpackChunkName: "component---src-pages-2017-05-03-listen-up-index-mdx" */),
  "component---src-pages-2017-06-21-data-poetry-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-06-21-Data-Poetry/index.mdx" /* webpackChunkName: "component---src-pages-2017-06-21-data-poetry-index-mdx" */),
  "component---src-pages-2017-07-05-modern-frenemies-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-07-05-Modern-Frenemies/index.mdx" /* webpackChunkName: "component---src-pages-2017-07-05-modern-frenemies-index-mdx" */),
  "component---src-pages-2017-07-21-creative-chaos-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-07-21-Creative-Chaos/index.mdx" /* webpackChunkName: "component---src-pages-2017-07-21-creative-chaos-index-mdx" */),
  "component---src-pages-2017-09-14-what-men-want-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-09-14-What-Men-Want/index.mdx" /* webpackChunkName: "component---src-pages-2017-09-14-what-men-want-index-mdx" */),
  "component---src-pages-2017-10-12-holiday-planning-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-10-12-Holiday-Planning/index.mdx" /* webpackChunkName: "component---src-pages-2017-10-12-holiday-planning-index-mdx" */),
  "component---src-pages-2017-11-02-creating-cult-brands-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-11-02-Creating-Cult-Brands/index.mdx" /* webpackChunkName: "component---src-pages-2017-11-02-creating-cult-brands-index-mdx" */),
  "component---src-pages-2017-12-11-unfinished-business-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-12-11-Unfinished-Business/index.mdx" /* webpackChunkName: "component---src-pages-2017-12-11-unfinished-business-index-mdx" */),
  "component---src-pages-2017-12-21-2018-thought-starters-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-12-21-2018-Thought-Starters/index.mdx" /* webpackChunkName: "component---src-pages-2017-12-21-2018-thought-starters-index-mdx" */),
  "component---src-pages-2018-05-10-powerbranding-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-05-10-Powerbranding/index.mdx" /* webpackChunkName: "component---src-pages-2018-05-10-powerbranding-index-mdx" */),
  "component---src-pages-2018-08-08-gen-z-impact-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-08-08-Gen-Z-Impact/index.mdx" /* webpackChunkName: "component---src-pages-2018-08-08-gen-z-impact-index-mdx" */),
  "component---src-pages-2018-09-12-lifestyle-binge-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-09-12-Lifestyle-Binge/index.mdx" /* webpackChunkName: "component---src-pages-2018-09-12-lifestyle-binge-index-mdx" */),
  "component---src-pages-2018-09-20-retails-human-problem-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-09-20-Retails-Human-Problem/index.mdx" /* webpackChunkName: "component---src-pages-2018-09-20-retails-human-problem-index-mdx" */),
  "component---src-pages-2018-10-02-cultural-mic-drop-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-10-02-Cultural-Mic-Drop/index.mdx" /* webpackChunkName: "component---src-pages-2018-10-02-cultural-mic-drop-index-mdx" */),
  "component---src-pages-2018-10-15-shape-shifter-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-10-15-Shape-Shifter/index.mdx" /* webpackChunkName: "component---src-pages-2018-10-15-shape-shifter-index-mdx" */),
  "component---src-pages-2019-01-31-inside-louis-vuittons-nyc-pop-up-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2019-01-31-Inside-Louis-Vuittons-NYC-Pop-Up/index.mdx" /* webpackChunkName: "component---src-pages-2019-01-31-inside-louis-vuittons-nyc-pop-up-index-mdx" */),
  "component---src-pages-2017-07-06-co-everything-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2017-07-06-Co-Everything/index.mdx" /* webpackChunkName: "component---src-pages-2017-07-06-co-everything-index-mdx" */),
  "component---src-pages-2018-10-31-how-netflix-can-save-retail-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2018-10-31-How-Netflix-Can-Save-Retail/index.mdx" /* webpackChunkName: "component---src-pages-2018-10-31-how-netflix-can-save-retail-index-mdx" */),
  "component---src-pages-2019-03-12-flex-state-thriving-in-the-age-of-disruption-index-mdx": () => import("/Users/dsit/Desktop/code/projects/mdx3/src/pages/2019-03-12-FlexState-Thriving-in-the-Age-of-Disruption/index.mdx" /* webpackChunkName: "component---src-pages-2019-03-12-flex-state-thriving-in-the-age-of-disruption-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/dsit/Desktop/code/projects/mdx3/.cache/data.json")

