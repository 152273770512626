import React from 'react'
import Image from '../../components/image'

const PortraitsTwo = ({ data }) => 
<div style={{display:'flex', margin: '0 -15px'}}>
    { 
      data.map((item, index) => {
          return (
            <div style={{width:'50%', padding: '0 15px', marginBottom: "30px"}} key={index}>
             <Image filename={item.imageSrc} alt={item.caption}/>
             <p className="imgCap">{item.caption}</p>
           </div>
          );
      })
    }
  </div>
export default PortraitsTwo;

/*
import React from 'react'

const PortraitsTwo = ({ data }) => 
<div style={{display:'flex', margin: '0 -15px'}}>
    { 
      data.map((item, index) => {
          return (
            <div style={{width:'50%', padding: '0 15px'}} key={index}>
             <img src={item.imageSrc}/>

             <span className="imgCap">{item.caption}</span>
           </div>
          );
      })
    }
  </div>
export default PortraitsTwo;
*/