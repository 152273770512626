import React from 'react'
import Image from '../../components/image'


const ImageLandscape = ({ imageSrc, caption }) => {
    //const small = data.imageSmall
    //const medium = data.imageMedium
    //const large = data.imageLarge
        return (
            <div style={{marginBottom: "30px"}}>
                <Image filename={imageSrc} alt={caption}/>
                <p className="imgCap">{caption}</p>
            </div>
        );
    }

export default ImageLandscape;

/*

    console.log(this.props);
    return (
        <div style={{marginBottom: "30px"}}>
            <Image filename={data.imageSrc} alt={data.caption}/>
             <p className="imgCap">{data.caption}</p>
        </div>
    )

    */