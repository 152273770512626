import Img from "gatsby-image";
import SEO from "../../../src/components/seo";
import ImageLandscape from "../../../src/components/sub/landscape.js";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  Img,
  SEO,
  ImageLandscape,
  React,
  MDXTag
};